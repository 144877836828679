import React from "react";
import {
    TextField,
    Filter,
    List,
    Datagrid,
    Show,
    SimpleShowLayout,
    ArrayField,
    DateField,
    BooleanInput,
    SimpleForm,
    ReferenceManyField,
    SingleFieldList
} from "react-admin";
import baseUrl from './config'

const ReportsFilter = props => (
    <Filter {...props}></Filter>
);

const handleClick = (props) => {
    const token = localStorage.getItem("token");

    fetch(`${baseUrl}/api/admin/reports/report_content/${props.id}`,
        {
            method: 'PUT',
            headers: new Headers({
                Authorization: `Bearer ${token}`
            }),
            body: null
        })
}

export const ReportsList = props => {
    return (
        <List {...props} filters={<ReportsFilter />} bulkActionButtons={false}>
            <Datagrid rowClick="show">
                <TextField source="reported_content.content_title" label="Content Title" />
                <ReferenceManyField label="Content Owner" source="content_owner" target="_id" reference="users">
                    <SingleFieldList>
                        <TextField source="u_username" />
                    </SingleFieldList>
                </ReferenceManyField>
                <TextField source="report_count" label="Report count" />
            </Datagrid>
        </List>
    );
};

export const ReportShow = props => {
    return (
        <Show title={"Report Details"} {...props}>
            <SimpleShowLayout>
                <TextField source="reported_content.content_title" label="Content Title" />
                <TextField source="reported_content.content_description" label="Content Description" />
                <ArrayField source="reported_by" >
                    <Datagrid>
                        <TextField source="reported_by.u_username" label="User name" />
                        <TextField source="reported_by.u_email" label="Email ID" />
                        <TextField source="report_reason" />
                        <DateField source="reported_at" />
                    </Datagrid>
                </ArrayField>
                <SimpleForm toolbar={false}>
                    <BooleanInput onChange={() => handleClick(props)} source="reported_content.content_reported" label="Accept Report" />
                </SimpleForm>
            </SimpleShowLayout>
        </Show>
    );
};