import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Edit,
  DisabledInput,
  TextInput,
  Filter,
  Create,
  ImageInput,
  ImageField,
  required,
  number
} from "react-admin";

const validateOrder = number();

export const FocusEdit = props => {
  return (
    <Edit title={"Edit Focus"} {...props}>
      <SimpleForm >
        <DisabledInput label="Id" source="id" />
        <TextInput source="focus" label="Focus" validate={required()} />
        <TextInput
          source="focus_display_name"
          label="Display Name"
          validate={required()}
        />
        <ImageInput source="focus_image_url" label="Focus Image" accept="image/*" validate={required()}>
          <ImageField source="src" label="Image" />
        </ImageInput>
        <TextInput source="focus_display_order" label="Order" validate={validateOrder} />
      </SimpleForm>
    </Edit>
  );
};


const FocusFilter = props => (
  <Filter {...props}>
 
  </Filter>
);

export const FocusCreate = props =>{
  return(
    <Create {...props} title={"Create Focus"}>
      <SimpleForm>
        <TextInput source="focus" label="Focus" />
        <TextInput source="focus_display_name" label="Display Name" />
        <TextInput source="focus_display_order" label="Order" />
        <ImageInput source="focus_image_url" label="Focus Image" accept="image/*">
          <ImageField source="src" label="Image" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )
}

export const FocusList = props => {
  return (
    <List {...props} filters={<FocusFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="focus" label="Focus" />
        <TextField source="focus_display_name" label="Display Name" />
        <ImageField source="focus_image_url" label="Image" />
        <TextField source="focus_display_order" label="Order" />
      </Datagrid>
    </List>
  );
};
