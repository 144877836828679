import React from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import { UserList, UserEdit } from './users';
import { QuestionsList, QuestionsEdit } from './assesment_questions';
import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';
import Dashboard from './Dashboard';
import { FocusList, FocusEdit, FocusCreate } from './focus.js'
import { ContentCategoriesList, ContentCategoriesEdit, ContentCategoriesCreate } from './contentCategories'
import uploadImage from './addUploadFeature';
import { ReportsList, ReportShow } from './reports';
import { PractitionersList, PractitionerShow } from './practitioners';
import { Feedbacklist } from './feedback';
import { CulturalHeritageList, CulturalHeritageEdit, CulturalHeritageCreate } from './cultural_heritages';
import { SpiritualOrientationList, SpiritualOrientationEdit, SpiritualOrientationCreate } from './spiritual_orientations';
import { Citieslist, CitiesEdit, CitiesCreate } from './cities';
import { Stateslist, StatesEdit, StatesCreate } from './states';
import { Countrieslist, CountriesEdit, CountriesCreate } from './countries';
import baseUrl from './config'
import { TestimonialList, CreateTestimonial, EditTestimonial } from './testimonial';

const httpClient = (url, options = {}) => {
  const token = localStorage.getItem('token');
  console.log('url :', url)
  // options.headers = new Headers({ 'Content-Type': 'multipart/form-data' });

  options.user = {
    authenticated: true,
    token: `Bearer ${token}`

  }

  return fetchUtils.fetchJson(url, options);
}
const dataProvider = simpleRestProvider(`${baseUrl}/api/admin`, httpClient);
const uploadCapableDataProvider = uploadImage(dataProvider);

const App = () => (
  <Admin dashboard={Dashboard} dataProvider={uploadCapableDataProvider} authProvider={authProvider}>
    <Resource name="users" list={UserList} edit={UserEdit} />
    <Resource name="assesment_questions" list={QuestionsList} edit={QuestionsEdit} />
    <Resource name="focus" list={FocusList} edit={FocusEdit} create={FocusCreate} />
    <Resource name="content_categories" list={ContentCategoriesList} edit={ContentCategoriesEdit} create={ContentCategoriesCreate} />
    <Resource name="reports" list={ReportsList} show={ReportShow} />
    <Resource name="practitioners" list={PractitionersList} show={PractitionerShow} />
    <Resource name="cultural_heritages" list={CulturalHeritageList} edit={CulturalHeritageEdit} create={CulturalHeritageCreate} />
    <Resource name="spiritual_orientations" list={SpiritualOrientationList} edit={SpiritualOrientationEdit} create={SpiritualOrientationCreate} />
    <Resource name="feedback" list={Feedbacklist} />
    <Resource name="cities" list={Citieslist} edit={CitiesEdit} create={CitiesCreate} />
    <Resource name="states" list={Stateslist} edit={StatesEdit} create={StatesCreate} />
    <Resource name="countries" list={Countrieslist} edit={CountriesEdit} create={CountriesCreate}/>
    <Resource name="assessment_categories" />
    <Resource name="testimonials" list={TestimonialList} create={CreateTestimonial} edit={EditTestimonial} />
  </Admin>
);

export default App;