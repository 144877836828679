import React from 'react';
import {
    TextField,
    List,
    Datagrid,
    Edit,
    SimpleForm,
    DisabledInput,
    TextInput,
    ArrayField,
    Create
} from "react-admin";

export const Stateslist = props => {
    return (
        <List {...props} bulkActionButtons={false}>
            <Datagrid rowClick="edit">
                {/* <TextField source="id" label="ID"></TextField> */}
                <TextField source="name" label="Name"></TextField>
                {/* <TextField source="country_id" label="Country ID"></TextField> */}
            </Datagrid>
        </List>
    )
}

export const StatesEdit = props => {
    return (
        <Edit title={"Edit State"} {...props}>
            <SimpleForm>
                <DisabledInput source="id" label="Id" />
                <TextInput source="name" label="Name" />
                {/* <TextInput source="country_id" label="Country ID" /> */}
                <ArrayField source="cities" >
                    <Datagrid>
                        <TextField source="name" label="city" />
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
        </Edit>
    )
}

export const StatesCreate = props => {
    return (
        <Create {...props} title={"Create City"}>
            <SimpleForm>
                <TextInput source="name" label="Name" />
                <TextInput source="country_id" label="Country ID" />
            </SimpleForm>
        </Create>
    )
}