import React from 'react';
import {
    TextField,
    List,
    Datagrid,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    BooleanInput,
    BooleanField,
    ArrayField,
    Filter
} from "react-admin";

export const Countrieslist = props => {
    return (
        <List {...props}
            filters={<CountriesFilter />}
            bulkActionButtons={false}>
            <Datagrid rowClick="edit">
                <TextField source="sortname" label="Sort Name"></TextField>
                <TextField source="name" label="Name"></TextField>
                <TextField source="phoneCode" label="Phone Code"></TextField>
                <BooleanField source="isEnglishSpeaking" label="Is English Speaking"></BooleanField>

            </Datagrid>
        </List>
    )
}

export const CountriesEdit = props => {
    return (
        <Edit title={"Edit Country"} {...props}>
            <SimpleForm>
                <TextInput source="sortname" label="Sort Name" />
                <TextInput source="name" label="Name" />
                <TextInput source="phoneCode" label="Phone Code" />
                <BooleanInput source="isEnglishSpeaking" label="Is English Speaking?" />
                <ArrayField source="states" >
                    <Datagrid>
                        <TextField source="name" label="state" />
                    </Datagrid>
                </ArrayField>
            </SimpleForm>
        </Edit>
    )
}

export const CountriesFilter = props => {
    return (
        <Filter {...props}>
            <TextInput label="Name" source="name" alwaysOn />
        </Filter>
    )
}

export const CountriesCreate = props => {
    return (
        <Create {...props} title={"Create City"}>
            <SimpleForm>
                <TextInput source="sortname" label="Sort Name" />
                <TextInput source="name" label="Name" />
                <TextInput source="phoneCode" label="Phone Code" />
                <BooleanInput source="isEnglishSpeaking" label="Is English Speaking?" />

            </SimpleForm>
        </Create>
    )
}