import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageInput,
  ImageField,
  SimpleForm,
  LongTextInput,
  Edit,
  TextInput,
  Create,
  required,
  number
} from "react-admin";

export const TestimonialList = props => {
    return (
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <ImageField source="t_image_url" label="Image" />
            <TextField source="t_name" label="Name" />
            <TextField source="t_message" label="Message" />
            <TextField source="t_slide_order" label="Order" />
        </Datagrid>
      </List>
    );
};

export const CreateTestimonial = props =>{
    return(
      <Create {...props} title={"Create Tesitimonial"}>
        <SimpleForm>
          <TextInput source="t_name" label="Name" validate={required()} />
          <LongTextInput source="t_message" label="Message" validate={required()} />
          <TextInput source="t_slide_order" label="Order" validate={number()} />
          <ImageInput source="t_image_url" label="Image" accept="image/*" validate={required()}>
            <ImageField source="src" label="Image" />
          </ImageInput>
        </SimpleForm>
      </Create>
    )
}

export const EditTestimonial = props => {
    return (
      <Edit title={"Edit Testimonial"} {...props}>
        <SimpleForm>
          <TextInput source="t_name" label="Name" validate={required()} />
          <TextInput source="t_message" label="Display Name" validate={required()} />
          <TextInput source="t_slide_order" label="Order" validate={number()} />
          <ImageInput source="t_image_url" label="Image" accept="image/*" validate={required()}>
            <ImageField source="src" label="Image" />
          </ImageInput>
        </SimpleForm>
      </Edit>
    );
};