import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Edit,
  DisabledInput,
  TextInput,
  BooleanInput,
  Filter,
  Toolbar,
  SelectInput,
  SaveButton,
} from "react-admin";



export const UserEdit = props => {
  return (
    <Edit title={"Disable User"} {...props}>
      <SimpleForm toolbar={<UserEditToolbar />}>
        <DisabledInput label="Id" source="id" />
        <DisabledInput label="Username" source="u_username" />
        <BooleanInput source="u_active" />
      </SimpleForm>
    </Edit>
  );
};

const UserEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Username" source="u_username" alwaysOn />
    <BooleanInput source="u_active" />
    <SelectInput
      source="u_type"
      choices={[
        { id: "client", name: "client" },
        { id: "practitioner", name: "practitioner" }
      ]}
    />
  </Filter>
);


export const UserList = props => {
  return (
    <List
      {...props}
      filters={<UserFilter />}
      filterDefaultValues={{ u_active: true }}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="u_fullname" label="Full Name"/>
        <TextField source="u_username" label="Username"/>
        <TextField source="u_email" label="Email" />
        <TextField source="u_active" label="Active" />
        <TextField source="u_type" label="User Type"/>
      </Datagrid>
    </List>
  );
};
