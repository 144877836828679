import React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Edit,
  DisabledInput,
  TextInput,
  Filter,
  Create,
  BooleanField,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  // ReferenceArrayField,
  SingleFieldList,
  ChipField,
  required,
  number
} from "react-admin";

const validateOrder = number();

const ContentCategoriesFilter = props => (
  <Filter {...props}>

  </Filter>
);

export const ContentCategoriesList = props => {
  return (
    <List {...props} filters={<ContentCategoriesFilter />} bulkActionButtons={false}>
      <Datagrid rowClick="edit">
        <TextField source="category_name" label="Name" />
        <TextField source="assessment_questions" label="Assessment Questions" />
        <TextField source="sub_categories" label="Sub Categories" />
        <TextField source="keywords" label="Keywords" />
        <ArrayField source="focus" label="focus" >
          <SingleFieldList>
            <ChipField source="focus_display_name" />
          </SingleFieldList>
        </ArrayField>
        <BooleanField source="filters.practitioner_specialization" label="Practitioner Specialization" />
        <BooleanField source="filters.client_area_to_improve" label="Client area to improve" />
        <ArrayField source="assessment_category" label="Category" >
          <SingleFieldList>
            <ChipField source="category_name" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};

export const ContentCategoriesEdit = props => {
  return (
    <Edit title={"Edit Content Category"} {...props}>
      <SimpleForm >
        <DisabledInput label="Id" source="id" />
        <TextInput source="category_name" label="Category Name" validate={required()} />
        <ArrayInput source="assessment_questions">
          <SimpleFormIterator>
            <TextInput source="question_number" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="sub_categories">
          <SimpleFormIterator>
            <TextInput source="category_number" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="keywords">
          <SimpleFormIterator>
            <TextInput source="keyword" />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceArrayInput label="Focus" source="focus" reference="focus">
          <SelectArrayInput optionText="focus_display_name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="Category" source="assessment_category" reference="assessment_categories">
          <SelectArrayInput optionText="category_name" />
        </ReferenceArrayInput>
        <BooleanInput source="filters.practitioner_specialization" label="Practitioner Specialization" validate={validateOrder} />
        <BooleanInput source="filters.client_area_to_improve" label="Client Area to Improve" validate={validateOrder} />
      </SimpleForm>
    </Edit>
  );
};

export const ContentCategoriesCreate = props => {
  return (
    <Create {...props} title={"Create Content Category"}>
      <SimpleForm>
        <TextInput source="category_name" label="Category Name" />
        <ReferenceArrayInput label="Focus" source="focus" reference="focus">
          <SelectArrayInput optionText="focus_display_name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput label="Category" source="assessment_category" reference="assessment_categories">
          <SelectArrayInput optionText="category_name" />
        </ReferenceArrayInput>
        <ArrayInput source="assessment_questions">
          <SimpleFormIterator>
            <TextInput source="question_number" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="sub_categories">
          <SimpleFormIterator>
            <TextInput source="category_number" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="keywords">
          <SimpleFormIterator>
            <TextInput source="keyword" />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source="filters.practitioner_specialization" label="Practitioner Specialization" validate={validateOrder} />
        <BooleanInput source="filters.client_area_to_improve" label="Client Area to Improve" validate={validateOrder} />
      </SimpleForm>
    </Create>
  )
}
